// import React from "react";
// import Prelaoder from "./src/Context/peloader";

// export  const wrapRootElement = ({ element }) => {
    
//     return (<Prelaoder>{element}</Prelaoder>) 
// }

// export function shouldUpdateScroll(prevRouterProps, { location }) {
//     window.scrollTo(0, 0)
//     const body = document.getElementsByTagName('body')[0]
//     body.scrollTop = 0
//     return false
// }

// exports.onRouteUpdate = () => {
//     if (window.APESTER) {
//        window.APESTER.reload();
//     } 
// }
// gatsby-browser.js

import React from 'react';
// import Preloader from './src/components/Preloader';

let preloaderTimeout;

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  const preloader = document.querySelector('#pre-loader');
  if (preloader) {
    console.log("loading");
  }
};

export const onRouteUpdate = () => {
  preloaderTimeout = setTimeout(() => {
    const preloader = document.querySelector('#pre-loader');
    if (preloader) {
    //   preloader.style.height = '0';
      console.log("loaded");
    }
  }, 3000); // Adjust delay if needed
};



